<template>
  <div>
    <van-nav-bar
      title="发起审批"
      left-text="返回"
      left-arrow
      @click-left="() => $router.back()"
    />
    <div style="margin: 10px 10px 0 0; border: 0px solid red">
      <form-create
        :rule="mergedRule"
        :option="option"
        @submit="onSubmit"
      ></form-create>
    </div>
  </div>
</template>

<script>
import api from '@/api/process'
import { MessageBox } from 'element-ui'

export default {
  name: 'process',

  data() {
    return {
      processTemplate: {},
      // 本身自带的表单
      rule: [],
      // 全局配置
      option: {},
      // 人员 表单
      rankRule: [],
      departmentList: [], // 部门数据
      personnelList: [], // 主送人员数据
      notifiedPersonList: [], // 抄送人员列表
      fileIdList: [], // 文件id数据
      mainGroupCount: 1, // 新增主送组计数器
      personnelOptions: {}, // 存储各主送组的人员选项
    }
  },

  created() {
    let processTemplateId = this.$route.params.processTemplateId
    this.fetchData(processTemplateId)
    // 获取部门数据
    this.fetchDepartmentData()
  },
  computed: {
    mergedRule() {
      return [...this.rule, ...this.rankRule]
    },
  },

  methods: {
    async fetchData(processTemplateId) {
      const response = await api.getProcessTemplate(processTemplateId)

      this.processTemplate = response.data
      this.rule = JSON.parse(this.processTemplate.formProps)
      this.option = JSON.parse(this.processTemplate.formOptions)

      // 等待文件上传的组件
      await this.addFileUploadField()

      if (this.processTemplate.templateType === 1) {
        await this.addDepartmentAndPersonnelFields()
      }
    },

    // 动态添加部门和人员选择字段
    async addDepartmentAndPersonnelFields() {
      await this.fetchDepartmentData()

      // 初始添加第一个主送组
      this.mainGroupCount = 1
      const mainGroupFields = this.generateMainGroupFields()

      // 抄送部门
      const notifiedField = {
        type: 'select',
        field: 'notifiedDepartment',
        title: '抄送部门',
        options: this.departmentList,
        props: {
          placeholder: '请选择抄送部门',
          multiple: true,
        },
        validate: [{ required: false, message: '请选择抄送部门' }],
        on: {
          change: this.onNotifiedChange,
        },
      }

      // 抄送人员
      const notifiedPersonnelField = {
        type: 'select',
        field: 'notifiedPerson',
        title: '抄送人员',
        options: [],
        props: {
          placeholder: '请选择抄送人员',
          multiple: true,
        },
        validate: [{ required: false, message: '请选择抄送人员' }],
      }

      // 添加按钮
      const addButton = {
        type: 'button',
        title: ' ',
        field: 'addButton',
        props: {
          type: 'primary',
          icon: 'el-icon-plus',
        },
        on: {
          click: this.addMainGroup,
        },
      }

      // 更新规则数组
      this.rankRule = [
        ...this.rankRule,
        ...mainGroupFields,
        addButton,
        notifiedField,
        notifiedPersonnelField,
      ]

      this.$forceUpdate()
    },

    // 添加主送组
    addMainGroup() {
      this.mainGroupCount += 1

      this.rankRule = [
        ...this.generateMainGroupFields(),
        ...this.rankRule.filter((field) => field.field === 'addButton'),
        ...this.rankRule.filter(
          (field) =>
            field.field === 'notifiedDepartment' ||
            field.field === 'notifiedPerson'
        ),
      ]

      this.$forceUpdate()
    },

    // 生成主送组字段
    generateMainGroupFields() {
      const fields = []
      for (let i = 1; i <= this.mainGroupCount; i++) {
        fields.push(
          // 主送组标题
          {
            type: 'vanTag',
            field: `departmentTitle_${i}`,
            children: ['主送组' + i],
            display: true,
            hidden: false,
            props: {
              type: 'primary',
              size: 'large',
            },
          },
          // 主送部门
          {
            type: 'select',
            field: `department_${i}`,
            title: '主送部门',
            options: this.departmentList,
            props: {
              placeholder: '请选择主送部门',
              multiple: true,
            },
            validate: [{ required: true, message: '请选择主送部门' }],
            on: {
              change: (value) => this.onDepartmentChange(value, i - 1),
            },
          },
          // 主送人员
          {
            type: 'select',
            field: `personnel_${i}`,
            title: '主送人员',
            options: this.personnelOptions[i - 1] || [],
            props: {
              placeholder: '请选择主送人员',
              multiple: true,
            },
            validate: [{ required: true, message: '请选择主送人员' }],
          }
        )
      }
      return fields
    },

    // 添加 文件上传的组件
    async addFileUploadField() {
      let token = window.localStorage.getItem('token') || ''

      const fileUploadField = {
        type: 'upload',
        field: 'file', // 字段名
        title: '附件上传',
        props: {
          multiple: false, // 是否支持多文件上传
          maxSize: 30 * 1024 * 1024, // 最大文件大小 (30MB)
          previewImage: true, // 是否预览
          beforeRemove: (file, fileList) => {
            // 获取当前文件在 fileList 中的下标
            let index = fileList.findIndex((f) => f.uid === file.uid) // 假设你用 uid 作为标识符

            // 获取对应的文件名
            let fileName = file.name

            return new Promise((resolve, reject) => {
              MessageBox.confirm(
                `确定要删除文件 "${fileName}" 吗？`,
                '确认删除',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                }
              )
                .then(() => {
                  api.deleteFileById(this.fileIdList[index])
                  this.fileIdList.splice(index, 1) // 删除对应的文件ID
                  resolve() // 返回 true，表示删除文件
                })
                .catch(() => {
                  // 用户点击取消，不删
                  reject() // 返回 false，表示取消删除
                })
            })
          },

          name: 'file', // 后端接收的字段名

          accept: '.pdf,.doc,.docx,.xlsx,.txt,.png,.jpg,.ppt,.pptx',

          action: '/prod-api/admin/upload/img', // 上传接口
          onSuccess: (response, file, fileList) => {
            if (response.code === 200) {
              // 提取返回的数据
              const fileId = response.data.id
              // 将文件ID和文件名添加到对应的列表中
              this.fileIdList.push(fileId)
              this.$message.success(`文件上传成功`)
            } else {
              this.$message.error('文件上传失败，请重试！')
            }
          },
          headers: {
            Token: `${token}`, // 如果需要认证，传递 Token
          },
        },
        validate: [{ required: false, message: '请上传文件' }],
      }

      const fileUploadDescription = {
        type: 'vanNoticeBar', // 定义为文本组件
        props: {
          text: '上传类型：ppt、work文档、excel、图片，上传大小限制30MB',
        },
      }

      // 将上传字段和说明文本一起加入 rankRule
      this.rankRule.push(fileUploadDescription)

      this.rankRule.push(fileUploadField)
    },

    // 获取部门数据
    async fetchDepartmentData() {
      try {
        const response = await api.getDepartmentList()
        this.departmentList = response.data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
        console.log('部门列表:', this.departmentList) // 打印检查数据格式
      } catch (error) {
        console.error('部门数据加载失败:', error)
      }
    },

    // 新增方法：获取部门对应人员
    async fetchPersonnelData(departmentIds) {
      if (departmentIds.length > 0) {
        api.selectedDepartments(departmentIds).then((response) => {
          this.departmentList = response.data.map((item) => ({
            label: item.name,
            value: item.username,
          }))
        })
      }
    },

    // 修改后的部门change处理
    async onDepartmentChange(value, groupIndex) {
      // 过滤无效值
      const validDepartments = value.filter(Boolean)
      if (validDepartments.length === 0) {
        this.personnelOptions[groupIndex] = []
        this.updatePersonnelField(groupIndex)
        return
      }

      // 获取对应部门的人员数据
      const personnel = await this.fetchPersonnelData(validDepartments)

      // 更新人员选项
      this.personnelOptions = {
        ...this.personnelOptions,
        [groupIndex]: personnel,
      }

      this.updatePersonnelField(groupIndex)
    },

    // 更新对应人员字段的公共方法
    updatePersonnelField(groupIndex) {
      let gindex = groupIndex + 1
      this.rankRule = this.rankRule.map((field) => {
        if (field.field === `personnel_${gindex}`) {
          return {
            ...field,
            options: this.personnelOptions[groupIndex] || [],
          }
        }
        return field
      })
      this.$forceUpdate()
    },
    // 根据选中的抄送部门获取对应的人员数据
    onNotifiedChange(selectedDepartments) {
      if (selectedDepartments.length > 0) {
        api.selectedDepartments(selectedDepartments).then((response) => {
          this.notifiedPersonList = response.data.map((item) => ({
            label: item.name,
            value: item.username,
          }))

          // 更新人员选择框的 options
          const personnelField = this.rankRule.find(
            (item) => item.field === 'notifiedPerson'
          )
          if (personnelField) {
            personnelField.options = this.notifiedPersonList
          }
        })
      }
    },
    // 提交
    onSubmit(formData) {
      console.log('formData:', formData)
      console.log('rule', this.rule)
      console.log('rankRule', this.rankRule)
      // 显示数据
      let formShowData = {}
      // 真实数据
      let newFormData = {}

      // 选中的人员
      let selectedPersonnel = []

      // 添加显示数据
      this.rule.forEach((item) => {
        for (let key in formData) {
          if (key === item.field) {
            formShowData[item.title] = formData[key]
            newFormData[item.field] = formData[key]
          }
        }
      })

      console.log('newFormData:', newFormData)

      // 主送人员
      this.rankRule.forEach((item) => {
        for (let key in formData) {
          if (key === 'personnel') {
            formData[key].forEach((id) => {
              if (!selectedPersonnel.includes(id)) {
                selectedPersonnel.push(id) // 直接添加 value
              }
            })
          }
        }
      })

      // 进行抄送
      let selectedNotified = []
      this.rankRule.forEach((item) => {
        for (let key in formData) {
          if (key === 'notifiedPerson') {
            formData[key].forEach((id) => {
              if (!selectedNotified.includes(id)) {
                selectedNotified.push(id) // 直接添加 value
              }
            })
          }
        }
      })

      let DATA = {
        formData: newFormData,
        formShowData: formShowData,
      }
      console.log('data:', DATA)

      let processFormVo = {
        processTemplateId: this.processTemplate.id,
        processTypeId: this.processTemplate.processTypeId,
        formValues: JSON.stringify(DATA),
        approvalNameList: selectedPersonnel,
        templateType: this.processTemplate.templateType,
        fileIds: this.fileIdList,
        notifiedPersonList: selectedNotified,
      }

      console.log('processFormVo', processFormVo)
      api.startUp(processFormVo).then((response) => {
        //调整到已发起列表
        this.$router.push({ path: '/list/2' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form {
  .el-form-item {
    /deep/ .el-form-item__label {
      font-size: 18px;
      font-weight: 800;
      color: blue;
    }
  }
}

.group-title {
  font-size: 16px;
  font-weight: bold;
  color: #606266;
  margin: 12px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #ebeef5;
}
</style>
